import { useNavigate } from "react-router-dom"
import "./index.css"

function LoginPopup({ overlayClick }) {
    const navigate = useNavigate()
    const LoginNavigate = () => {
        overlayClick()
        navigate("/Login")
    }
    return (
        <>
            <div className="login-popup-modal">
                <div className="overlay-modal" onClick={overlayClick}>
                </div>
                <div className="login-popup">
                    <p>
                        Please log in before adding items to your cart.
                    </p>
                    <button className="login-popup-btn" onClick={() => LoginNavigate()}>Login</button>
                </div>
            </div>
        </>
    )
}

export default LoginPopup
