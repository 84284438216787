import { faArrowLeft, faArrowRight, faHeart, faShoppingBag } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import { useRef, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import { BACKEND_Image_URL, BACKEND_URL } from '../../env';
import NotFound from "../../images/notFound.webp";
import Dropdowns from "../Dropdown";
import "./index.css";

function Trending({ onClick, AllCategory, AllProducts, getWishlist }) {
    const navigate = useNavigate();
    const itemListRef = useRef(null);
    
    // Filter categories to show only those with products
    const availableCategories = AllCategory.filter(category => 
        AllProducts.some(product => product.category === category.id)
    );
    
    const [activeCategory, setActiveCategory] = useState(availableCategories[0]?.id);
    console.log(availableCategories[0]?.id, activeCategory);

    const filteredProducts = AllProducts
        .filter(product => product.category === activeCategory)
        .slice(0, 8); // Only show 8 products per category

    const scrollLeft = () => {
        itemListRef.current.scrollBy({ left: -220, behavior: 'smooth' });
    };

    const scrollRight = () => {
        itemListRef.current.scrollBy({ left: 220, behavior: 'smooth' });
    };

    const handleCategoryClick = (categoryName) => {
        const category = availableCategories.find(cat => cat.name === categoryName);
        
        if (category) {
            console.log(category.id);
            setActiveCategory(category.id);
        }
    };

    const storedLoginData = JSON.parse(localStorage.getItem("loginData"));

    const handleAddToCartClick = (product) => {
        if (storedLoginData && storedLoginData.token) {
            let cart = JSON.parse(localStorage.getItem("cart")) || [];
            const productIndex = cart.findIndex((item) => item.id === product.id);

            if (productIndex === -1) {
                cart.push({ ...product, quantity: 1 });
                toast.success("Successfully added to cart");
            } else {
                cart[productIndex].quantity += 1;
                toast.success("Product quantity updated in cart");
            }

            localStorage.setItem("cart", JSON.stringify(cart));
        } else {
            onClick();
        }
    };

    const handleAddToWishlistClick = async (product) => {
        if (storedLoginData && storedLoginData.token) {
            try {
                const response = await axios.post(`${BACKEND_URL}/wishlist`, {
                    productId: product.id
                }, {
                    headers: { token: storedLoginData.token }
                });

                if (response.status === 200) {
                    toast.success("Product added to wishlist");
                    getWishlist();
                }
            } catch (error) {
                console.error("Error adding product to wishlist:", error);
            }
        } else {
            onClick();
        }
    };
    function shuffleArray(array) {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
    }
    
    return (
        <div className="trending-container">
            <div className="trending-main">
                <h2>TRENDING ITEMS</h2>
                <div className="trending-item-dropdown">
                    <Dropdowns onSelect={handleCategoryClick} categories={availableCategories?.map(category => category.name)} />
                </div>
                <div className="trending-item-list" ref={itemListRef}>
                    {availableCategories.map((e, i) => (
                        <p
                            key={i}
                            className={`category-item ${activeCategory === e.id ? 'active' : ''}`}
                            onClick={() => handleCategoryClick(e.name)}
                        >
                            {e.name}
                        </p>
                    ))}
                </div>
                <div className='trending-arrow-container'>
                    <div className='trending-arrow' onClick={scrollLeft}>
                        <FontAwesomeIcon icon={faArrowLeft} color="#000" style={{ fontSize: 12 }} />
                    </div>
                    <div className='trending-arrow' onClick={scrollRight}>
                        <FontAwesomeIcon icon={faArrowRight} color="#000" style={{ fontSize: 12 }} />
                    </div>
                </div>
            </div>
            <div className="trending-item-container">
                {shuffleArray(filteredProducts)?.map((e, i) => (
                    <div key={i} className="trending-item">
                        <img
                            src={e.images?.length > 0 ?
                                e.images[0]?.startsWith("products/http") || e.images[0]?.startsWith("products/https")
                                    ? e.images[0]?.replace("products/", "")
                                    : `${BACKEND_Image_URL}${e.images[0]}` : NotFound
                            }
                            alt="" className="trending-item-image" onClick={() => navigate("/ProductDetail", { state: e })} />
                        <div className="trending-items-content">
                            <p className="trending-items-name">{e.title}</p>
                            <p className="trending-items-price">{e.price} Aed</p>
                            <div style={{ display: "flex" }}>
                                <div className="trending-addcart" onClick={() => handleAddToCartClick(e)}>
                                    <FontAwesomeIcon icon={faShoppingBag} className="trending-icon" />
                                    <p>Add to Cart</p>
                                </div>
                                <div className="trending-addcart" style={{ marginLeft: 10 }} onClick={() => handleAddToWishlistClick(e)}>
                                    <FontAwesomeIcon icon={faHeart} className="trending-icon" />
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Trending;
