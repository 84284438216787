import React, { useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
        href=""
        ref={ref}
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}
        style={{ fontFamily: "Kanit", textDecoration: "none", color: "#444", fontSize: "13px" }}
    >
        {children} &#x25bc;
    </a>
));

const CustomMenu = React.forwardRef(
    ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
        const [value, setValue] = useState('');

        return (
            <div
                ref={ref}
                style={style}
                className={className}
                aria-labelledby={labeledBy}
            >
                {/* <Form.Control
                    autoFocus
                    className="mx-3 my-2"
                    onChange={(e) => setValue(e.target.value)}
                    value={value}
                    style={{ width: "130px", fontFamily: "Kanit", textDecoration: "none", color: "#444", fontSize: "13px" }}
                /> */}
                <ul className="list-unstyled">
                    {React.Children.toArray(children).filter(
                        (child) =>
                            !value || child.props.children.toLowerCase().startsWith(value.toLowerCase())
                    )}
                </ul>
            </div>
        );
    }
);

function SortFilter({ onSelect }) {
    const [selectedState, setSelectedState] = useState('');

    const handleSelect = (eventKey, event) => {
        setSelectedState(event.target.text);
        if (onSelect) {
            onSelect(event.target.text);
        }
    };

    const uaeStates = ["Features", "Best Selling", "Alphabetically, A-Z", "Alphabetically, Z-A", "Price, low to high", "Price, high to low", "Date, old to new","Date, new to old"];

    return (
        <Dropdown onSelect={handleSelect} style={{ padding: "5px 15px", background: "#F6F7FA" }}>
            <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components" style={{ fontFamily: "Kanit", fontWeight: "600", fontSize: "0.9rem" }}>
                {selectedState || "Sort Filter"}
            </Dropdown.Toggle>

            <Dropdown.Menu as={CustomMenu} style={{ width: "150px !important", marginTop: "10px" }}>
                {uaeStates.map((state, index) => (
                    <Dropdown.Item key={index} eventKey={index} style={{ fontFamily: "Kanit", textDecoration: "none", color: "#444", fontSize: "13px" }}>
                        {state}
                    </Dropdown.Item>
                ))}
            </Dropdown.Menu>
        </Dropdown>
    );
}

export default SortFilter;
