import { useState } from "react";
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import Sidebar from "../../components/sidebar";
import { withAppContext } from "../../context";
import ContactImage from "../../images/contactus.webp";

import { CircularProgress } from "@mui/material";
import { toast } from "react-toastify";
import "./index.css";
function Contact({ AllCategory, WishlistData, instagramData }) {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [loader, setLoader] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const openSidebar = () => {
    setSidebarOpen(true);
  }

  const closeSidebar = () => {
    setSidebarOpen(false);
  }
  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prev) => ({ ...prev, [id]: value }));
  };

  const handleSubmit = async (e) => {
    setLoader(true);
    try {
      const res = await fetch("https://api.web3forms.com/submit", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          access_key: "d913e7d7-a613-4dd4-8440-f0475c669836",
          ...formData,
        }),
      });

      if (res.ok) {
        setLoader(false);
        toast.success("Thank you! We have received your message.")
        setFormData({
          name: "",
          email: "",
          phone: "",
          message: "",
        })
      } else {
        setLoader(false);
        console.error("Error submitting form");
      }
    } catch (error) {
      setLoader(false);
      console.error("Error:", error);
    }
  };
  return (
    <>
      <Navbar openSidebar={openSidebar} AllCategory={AllCategory} WishlistData={WishlistData} />
      <Sidebar closeSidebar={closeSidebar} sidebarOpen={sidebarOpen} />
      <div className="contact-us-main-container">
        <div className="contact-welcome-container">
          <h3>Welcome to Deserts Dropshipper!</h3>
          <p>We are dedicated to providing top-notch dropshipping services in the UAE, ensuring your business runs smoothly with our reliable and efficient solutions. Whether you have questions, need support, or want to learn more about our services, we're here to help!</p>
          <h3>How to Reach Us</h3>
          {/* <h5>Customer Service Hours:
          </h5>
          <ul>
            <li><strong>Monday to Fridays</strong> 9:00 AM to 6:00 PM (GST)</li>
            <li><strong>Saturday:</strong> 10:00 AM to 4:00 PM (GST)</li>
            <li><strong>Sunday:</strong> Closed</li>
          </ul> */}
          <h5>Contact Information:</h5>
          <ul>
            <li><strong>Email:</strong> desertsdropshipperinfo@gmail.com</li>
            <li><strong>Phone:</strong> +971562779487</li>
          </ul>
          <p><strong>Office Address:</strong> Warehouse 16 street 6C Street - behind kangaroo plastics - Al Khabaisi - Dubai</p>
          <h5>Delivery Information:</h5>
          <ul>
            <li><strong>Delivery Time:</strong> 1 to 2 days</li>
            <li><strong>Delivery Charges:</strong> 17 AED</li>
            {/* <li><strong>No Fulfillment Charges:</strong> We cover all fulfillment costs to keep your expenses low.</li> */}
            {/* <li><strong>Free Returns:</strong> We offer free returns for your convenience and customer satisfaction.</li> */}
          </ul>
          <p><strong>No Fulfillment Charges:</strong> We cover all fulfillment costs to keep your expenses low.</p>
          <p><strong>Free Returns:</strong> We offer free returns for your convenience and customer satisfaction.</p>
        </div>
        <img src={ContactImage} alt="" className="contact-us-image" />
      </div>
      <div className="make-uniq">
        <h1 style={{ textAlign: "center", color: "#222", fontFamily: "Kanit", fontSize: "30px", fontWeight: "600" }}>
          Deserts Dropshipper Plus
        </h1>
        <div className="make_uniq">
          <div className="unique-item">
            <div className="plus-number">1</div>
            <h5 style={{ fontWeight: "700", fontFamily: "Kanit" }}>
              Sourcing
            </h5>
            <p style={{ fontWeight: "500", fontFamily: "Kanit", color: "hsla(240,7%,62%,1)" }}>Tell us the product you want to source and DesertsDropshipper will provide you the quotation</p>
          </div>
          <div className="unique-item">
            <div className="plus-number">2</div>
            <h5 style={{ fontWeight: "700", fontFamily: "Kanit" }}>Shipping</h5>

            <p style={{ fontWeight: "500", fontFamily: "Kanit", color: "hsla(240,7%,62%,1)" }}>Once you approve the price, we will handle the shipment and custom clearance to your desired destination</p>
          </div>
          <div className="unique-item">
            <div className="plus-number">3</div>
            <h5 style={{ fontWeight: "700", fontFamily: "Kanit" }}>Warehousing & Fulfilment</h5>
            <p style={{ fontWeight: "500", fontFamily: "Kanit", color: "hsla(240,7%,62%,1)" }}>We will store your product in our warehouse and get your products ready to be delivered</p>
          </div>
          <div className="unique-item">
            <div className="plus-number">4</div>
            <h5 style={{ fontWeight: "700", fontFamily: "Kanit" }}>Free Returns</h5>
            <p style={{ fontWeight: "500", fontFamily: "Kanit", color: "hsla(240,7%,62%,1)" }}>We offer free returns for your convenience and customer satisfaction.</p>
          </div>
          <div className="unique-item">
            <div className="plus-number">5</div>
            <h5 style={{ fontWeight: "700", fontFamily: "Kanit" }}>Order Delivery to your Customer</h5>
            <p style={{ fontWeight: "500", fontFamily: "Kanit", color: "hsla(240,7%,62%,1)" }}>Once orders are confirmed, DesertsDropshipper will deliver them to your customers</p>
          </div>
          <div className="unique-item">
            <div className="plus-number">6</div>
            <h5 style={{ fontWeight: "700", fontFamily: "Kanit" }}>COD Remittance</h5>
            <p style={{ fontWeight: "500", fontFamily: "Kanit", color: "hsla(240,7%,62%,1)" }}>DesertsDropshipperwill transfer the funds received from your customers to your bank account, every week</p>
          </div>
        </div>
      </div>
      <section className="contact-form">
        <div className="form-container">
          <h2 >Your Details</h2>
          <form action="#" method="POST">

            {/* <label for="name">Name: </label>
            <input type="text" id="name" name="name" required />

            <label for="email">Email: </label>
            <input type="email" id="email" name="email" required />

            <label for="phone">Phone: </label>
            <input type="tel" id="phone" name="phone" />

            <label for="message">Message: </label>
            <textarea id="message" name="message" rows="4" required></textarea> */}
            <label htmlFor="name">Name: </label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />

            <label htmlFor="email">Email: </label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />

            <label htmlFor="phone">Phone: </label>
            <input
              type="tel"
              id="phone"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
            />

            <label htmlFor="message">Message: </label>
            <textarea
              id="message"
              name="message"
              rows="4"
              value={formData.message}
              onChange={handleChange}
              required
            ></textarea>
            <button type="button" className="submit-button" onClick={handleSubmit}>{loader ? <CircularProgress size={15} sx={{ color: "#fff" }} /> : "Submit"}</button>
          </form>
        </div>
      </section>
      <Footer instagramData={instagramData} />
    </>

  )

}
export default withAppContext(Contact)

