import "./index.css";
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function Whatsapp() {
    const handleClick = () => {
        const phoneNumber = "+971562779487";
        const url = `https://wa.me/${phoneNumber}`;
        window.open(url, "_blank"); // Opens the URL in a new tab or window
    };

    return (
        <button className="whatsapp" onClick={handleClick}>
            <FontAwesomeIcon icon={faWhatsapp} color="white" />
        </button>
    );
}

export default Whatsapp;
