import "./index.css"
function TopTags({ getTags }) {

    return (
        <div className="toptags-container">
            <h2>TOP TAGS</h2>
            <div className="toptags-main">
                {getTags?.map((e) => (
                    <p className="tags">{e.name}</p>
                ))}
            </div>
        </div>
    )
}
export default TopTags