import "./index.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGift, faAngleRight, faPlug, faShoppingBag, faPlusSquare, faMinusSquare, faBars } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import CategorySidebar from "../CategorySidebar";
import { BACKEND_Image_URL } from "../../env";
import { useNavigate } from "react-router-dom";

function AllProductCategory({ AllCategory, setSelectedCategory, selectedCategory, highestPrice, toPrice, fromPrice, setToPrice, setFromPrice }) {
    const [hoveredCategory, setHoveredCategory] = useState(null);
    const [showAllCategories, setShowAllCategories] = useState(false);
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [hoverTimeout, setHoverTimeout] = useState(null);

    const openSidebar = () => {
        setSidebarOpen(true);
    }

    const closeSidebar = () => {
        setSidebarOpen(false);
    }

    // const displayedCategories = showAllCategories ? AllCategory : AllCategory.slice(0, 5);

    const handleMouseEnter = (index) => {
        if (hoverTimeout) {
            clearTimeout(hoverTimeout);
            setHoverTimeout(null);
        }
        setHoveredCategory(index);
    };

    const handleMouseLeave = () => {
        const timeoutId = setTimeout(() => {
            setHoveredCategory(null);
        }, 300);
        setHoverTimeout(timeoutId);
    };
    const handleCategoryClick = (categoryId) => {
        setSelectedCategory(categoryId);
    };
    const navigate = useNavigate()
    return (
        <>
            <div className="AllproductCategory-container">
                <FontAwesomeIcon icon={faBars} className="menu-icon" onClick={() => openSidebar()} />
                <h2>ALL CATEGORIES</h2>
                <div className="AllproductCategory-main">
                    {AllCategory.length && AllCategory.map((category, index) => (
                        <div
                            key={index}
                            className={selectedCategory === category.id ? "SelectedCategory" : "AllproductCategory"}
                            onMouseEnter={() => category?.subcategories ? handleMouseEnter(index) : console.log("no Category")
                            }
                            onMouseLeave={handleMouseLeave}
                            onClick={() => navigate(`/AllProduct/${category.name}`)}
                        >
                            <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
                                <img
                                    src={
                                        category.Image.startsWith("category/http") || category.Image.startsWith("category/https")
                                            ? category.Image.replace("category/", "")
                                            : `${BACKEND_Image_URL}${category.Image}`
                                    }
                                    // src={`${BACKEND_Image_URL}${category.Image}`}
                                    width={25} />
                                <p className="AllproductCategory-text">{category.name}</p>
                            </div>
                            <FontAwesomeIcon icon={faAngleRight} className="AllproductCategory-icon" />
                            {hoveredCategory === index && (
                                <div
                                    className="subcategories"
                                    onMouseEnter={() => handleMouseEnter(index)}
                                    onMouseLeave={handleMouseLeave}
                                >
                                    {category?.subcategories?.map((sub, subIndex) => (
                                        <p key={subIndex} className="AllproductCategory-text">{sub}</p>
                                    ))}
                                </div>
                            )}
                        </div>
                    ))}
                    {AllCategory.length > 7 ? <div className="more-category-container" onClick={() => setShowAllCategories(!showAllCategories)}>
                        <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
                            <FontAwesomeIcon icon={showAllCategories ? faMinusSquare : faPlusSquare} className="more-category-icon" />
                            <p className="more-category-text">{showAllCategories ? "Close Category" : "More Category"}</p>
                        </div>
                    </div> : null}
                </div>
                <div className="allproductcategory-price-container">
                    <h2>Price</h2>
                    <div className="reset-and-text">
                        <p>The highest price is {highestPrice} AED</p>
                        <button className="reset-btn">Reset</button>
                    </div>
                    {/* <div className="name-input-div"> */}
                    <input
                        type="text"
                        placeholder="From"
                        value={fromPrice}
                        onChange={(e) => setFromPrice(e.target.value)}
                        // className="simple-input"
                        className="price-filter-input"
                    // required
                    />
                    <input
                        type="text"
                        placeholder="To"
                        value={toPrice}
                        onChange={(e) => setToPrice(e.target.value)}
                        // className="simple-input"
                        className="price-filter-input"
                    // required
                    />
                    {/* </div> */}
                </div>
            </div>
            <CategorySidebar closeSidebar={closeSidebar} sidebarOpen={sidebarOpen} categories={AllCategory} />
        </>
    );
}

export default AllProductCategory;
