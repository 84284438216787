import React, { useEffect, useState } from 'react';
import './index.css';
import Navbar from '../../components/Navbar';
import { useNavigate } from 'react-router-dom';

const Balloon = ({ position }) => (
    <div className={`balloon ${position}`}></div>
);

const RewardCard = ({ onClick, showReward, claimReward }) => (
    <div id="rewardCard">
        <h2>Congratulations!</h2>
        <p>You have won $100.</p>
        {/* <button id="claimButton" onClick={onClick}>Claim</button> */}
    </div>
);

const Confetti = ({ show }) => {
    if (!show) return null;

    const confettiPieces = Array.from({ length: 100 }).map((_, i) => {
        const styles = {
            left: `${Math.random() * 100}vw`,
            top: `${Math.random() * 100}vh`,
            animationDelay: `${Math.random() * 2}s`,
            backgroundColor: `hsl(${Math.random() * 360}, 70%, 60%)`,
            width: `${Math.random() * 10 + 5}px`,
            height: `${Math.random() * 10 + 5}px`,
        };
        return <div key={i} style={styles} className="confetti-piece"></div>;
    });

    return <div id="confetti" className="confetti">{confettiPieces}</div>;
};

function RewardCelebration() {
    const navigate = useNavigate()
    const [showReward, setShowReward] = useState(false);
    const [showConfetti, setShowConfetti] = useState(true);

    const handleShowReward = () => {
        setShowReward(true);
        setShowConfetti(true);
    };

    useEffect(() => {
        if (showConfetti) {
            const timer = setTimeout(() => {
                navigate('/Reward');
            }, 3000); // Adjust the timeout to match the length of your confetti animation

            return () => clearTimeout(timer);
        }
    }, [showConfetti,]);

    return (
        <div className="App">
            <Balloon position="left" />
            <Balloon position="right" />
            <RewardCard
                onClick={handleShowReward}
                showReward={showReward}
            />
            <Confetti show={showConfetti} />
        </div>
    );
}

export default RewardCelebration;
