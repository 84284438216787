import React, { useState } from 'react';
import "./index.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusSquare, faMinusSquare, faSearch } from '@fortawesome/free-solid-svg-icons';
import Logo from "../../images/desertsdroppshiper.png";
import Dropdown from "../Dropdown";
import SidebarDropdown from '../SidebarDropdown';
import { BACKEND_Image_URL } from '../../env';


function CategorySidebar({ sidebarOpen, closeSidebar, categories }) {
    const [subcategory, setSubCategory] = useState()
    return (
        <>
            <div className={`overlay ${sidebarOpen ? 'active' : ''}`} onClick={closeSidebar}></div>
            <div className={`sidebar ${sidebarOpen ? 'opensidebar' : ''}`}>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginBottom: "20px" }}>
                    <img src={Logo} alt='' width={200} />
                </div>
                <div className="sidebar-input-dropdown">
                    <SidebarDropdown />
                    <div style={{ width: "1px", height: "10px", background: "#ebebeb", marginLeft: 10, marginRight: 10 }}></div>
                    <input />
                    <button>
                        <FontAwesomeIcon icon={faSearch} color="white" />
                    </button>
                </div>
                {
                    categories?.map((e, i) => (
                        <>
                            <div key={i} className='categorysidebar'>
                                <div className='categorysidebar-main'>
                                    <img
                                        src={
                                            e.Image.startsWith("category/http") || e.Image.startsWith("category/https")
                                                ? e.Image.replace("category/", "")
                                                : `${BACKEND_Image_URL}${e.Image}`
                                        }
                                        // src={`${BACKEND_Image_URL}${e.Image}`}
                                        className={"category_image"} />
                                    <span style={{ color: "white" }} className="categorysidebar-text">{e?.name}</span>
                                </div>
                                {e?.subcategories ? <FontAwesomeIcon icon={subcategory === i ? faMinusSquare : faPlusSquare} className="categorysidebar-icon" onClick={() => { subcategory === i ? setSubCategory(null) : setSubCategory(i) }} />
                                    : null}
                                {/* <FontAwesomeIcon icon={faMinusSquare} className="categorysidebar-icon" onClick={() => setSubCategory(null)} /> */}
                            </div>
                            {subcategory === i && (
                                <div style={{ background: "#fff", margin: "10px", padding: "10px", borderRadius: "5px", marginTop: "0px" }}>
                                    {e?.subcategories?.map((sub, subIndex) => (
                                        <p key={subIndex} className="sidebar-subcategory-text">{sub}</p>
                                    ))}
                                </div>
                            )}
                        </>
                    ))
                }
            </div>
        </>
    );
}

export default CategorySidebar;
