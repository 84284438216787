import React, { useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
        href=""
        ref={ref}
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}
        style={{ fontFamily: "Kanit", textDecoration: "none", color: "#444", fontSize: "13px", }}
    >
        {children}
        &#x25bc;
    </a>
));

const CustomMenu = React.forwardRef(
    ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
        const [value, setValue] = useState('');

        return (
            <div
                ref={ref}
                style={style}
                className={className}
                aria-labelledby={labeledBy}
            >
                <ul className="list-unstyled">
                    {React.Children.toArray(children).filter(
                        (child) =>
                            !value || child.props.children.toLowerCase().startsWith(value),
                    )}
                </ul>
            </div>
        );
    },
);
function Dropdowns({ onSelect, categories }) {
    const [selectedCategory, setSelectedCategory] = useState('All Categories');

    const handleSelect = (eventKey) => {
        const selectedText = categories[eventKey];
        setSelectedCategory(selectedText);
        if (onSelect) {
            onSelect(selectedText);
        }
    };

    return (
        <Dropdown onSelect={handleSelect}>
            <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                {selectedCategory}
            </Dropdown.Toggle>

            <Dropdown.Menu as={CustomMenu} style={{ width: "150px !important", marginTop: "10px" }}>
                {categories.length > 0 ? (
                    categories.map((category, index) => (
                        <Dropdown.Item key={index} eventKey={index} style={{ fontFamily: "Kanit", textDecoration: "none", color: "#444", fontSize: "13px" }}>
                            {category}
                        </Dropdown.Item>
                    ))
                ) : (
                    <Dropdown.Item disabled style={{ fontFamily: "Kanit", color: "#888" }}>
                        No Categories Available
                    </Dropdown.Item>
                )}
            </Dropdown.Menu>
        </Dropdown>
    );
}

export default Dropdowns