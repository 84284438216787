import axios from "axios";
import React, { createContext, useState, useEffect } from "react";
import { BACKEND_URL } from "../env";
import { toast } from "react-toastify";
import LoaderView from "../components/Loader";

export const AppContext = createContext();
export const withAppContext = (Component) => (props) => (
  <AppContext.Consumer>
    {(value) => <Component {...value} {...props} />}
  </AppContext.Consumer>
);

const AppProvider = ({ children }) => {
  const [AllCategory, setAllCategory] = useState([]);
  const [AllMultipleProducts, setAllMultipleProducts] = useState([]);
  const [GetToken, setGetToken] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [loginData, setLoginData] = useState(null);
  const [loading, setLoading] = useState(false); // Add loading state
  useEffect(() => {
    // Fetch or manage login data
    const storedLoginData = JSON.parse(localStorage.getItem("loginData"));
    setLoginData(storedLoginData);
  }, []);
  function GetLogin(Obj, setLoading, history) {
    setLoading(true); // Start loading
    const config = {
      method: "post",
      url: `${BACKEND_URL}/auth/signin`,
      data: Obj,
    };
    axios(config)
      .then((res) => {
        if (res.data.token) {
          setGetToken(res.data.token);
          localStorage.setItem("Token", res.data.token);
          history.push("/admin/index");
          toast.success("Login Successfully", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
          });
        } else {
          toast.error(
            res?.data?.message ? res?.data?.message : "Invalid error",
            {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: false,
            }
          );
        }
        setLoading(false); // End loading
      })
      .catch(() => {
        setLoading(false); // End loading
      });
  }

  function GetAllCategories() {
    setLoading(true); // Start loading
    axios
      .get(`${BACKEND_URL}/categories`)
      .then((res) => {
        setAllCategory(res?.data?.getAllCategories);
        setLoading(false); // End loading
      })
      .catch(() => {
        setLoading(false); // End loading
      });
  }

  function GetAllMultipleProducts() {
    setLoading(true); // Start loading
    axios
      .get(`${BACKEND_URL}/filterProduct`)
      .then((res) => {
        setAllMultipleProducts(res.data);
        setLoading(false); // End loading
      })
      .catch(() => {
        setLoading(false); // End loading
      });
  }

  useEffect(() => {
    GetAllCategories();
  }, [!refresh]);

  const [AllProducts, setAllProducts] = useState([]);
  function GetAllProducts() {
    setLoading(true); // Start loading
    axios
      .get(`${BACKEND_URL}/products`)
      .then((res) => {
        setAllProducts(res.data.getAllProducts);
        setLoading(false); // End loading
      })
      .catch(() => {
        setLoading(false); // End loading
      });
  }

  // Tags
  const [addTagLoading, setAddTagLoading] = useState([]);
  const [TagsData, setTagsData] = useState([]);
  const [addTagsLoading, setAddTagsLoading] = useState([]);
  function getTags() {
    setAddTagsLoading(true); // Start loading
    axios
      .get(`${BACKEND_URL}/tag`)
      .then((data) => {
        setTagsData(data?.data?.getAllTags);
        setAddTagsLoading(false); // End loading
      })
      .catch(() => {
        setAddTagsLoading(false); // End loading
      });
  }
  const storedLoginData = JSON.parse(localStorage.getItem("loginData"));
  const [WishlistData, setWishlistData] = useState();
  function getWishlist() {
    axios
      .get(`${BACKEND_URL}/wishlist`, {
        headers: {
          token: storedLoginData?.token
        }
      })
      .then((data) => {
        setWishlistData(data?.data?.wishlist);
        // setAddWishlistLoading(false); // End loading
      })
      .catch(() => {
        // setAddWishlistLoading(false); // End loading
      });
  }
  const [sliderData, setSliderData] = useState();
  function getSlider() {
    axios
      .get(`${BACKEND_URL}/slider`, {
        headers: {
          token: storedLoginData?.token
        }
      })
      .then((data) => {
        setSliderData(data?.data?.getAllSliders);
        // setAddWishlistLoading(false); // End loading
      })
      .catch(() => {
        // setAddWishlistLoading(false); // End loading
      });
  }
  const [instagramData, setInstagramData] = useState();
  function getInstagram() {
    axios
      .get(`${BACKEND_URL}/instagram`, {
        headers: {
          token: storedLoginData?.token
        }
      })
      .then((data) => {
        setInstagramData(data?.data?.getAllInstagrams);
        // setAddWishlistLoading(false); // End loading
      })
      .catch(() => {
        // setAddWishlistLoading(false); // End loading
      });
  }

  return (
    <AppContext.Provider
      value={{
        AllCategory,
        GetAllCategories,
        GetAllMultipleProducts,
        AllMultipleProducts,
        GetAllProducts,
        GetLogin,
        GetToken,
        TagsData,
        addTagLoading,
        AllProducts,
        refresh,
        setRefresh,
        addTagsLoading,
        getTags,
        loading,
        loginData,
        getWishlist,
        WishlistData,
        sliderData,
        getSlider,
        getInstagram,
        instagramData,
      }}
    >
      {loading ? <LoaderView /> : children}
    </AppContext.Provider>
  );
};

export default AppProvider;
