import React, { useState } from "react";
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import Sidebar from "../../components/sidebar";
import { withAppContext } from "../../context";
import { faClipboard } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from "react-toastify";

function StoreIntegration({ AllCategory, WishlistData, instagramData }) {
    const [sidebarOpen, setSidebarOpen] = useState(false);

    const openSidebar = () => {
        setSidebarOpen(true);
    }

    const closeSidebar = () => {
        setSidebarOpen(false);
    }

    // Function to copy text to the clipboard
    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text)
            .then(() => {
                toast.success('Copied to clipboard!');
            })
            .catch((error) => {
                console.error('Error copying text: ', error);
            });
    }

    return (
        <>
            <Navbar openSidebar={openSidebar} AllCategory={AllCategory} WishlistData={WishlistData} />
            <Sidebar closeSidebar={closeSidebar} sidebarOpen={sidebarOpen} />
            <div className="privacy-policy-maindiv">
                <div className="privacy-policy">
                    <div className="policy-heading-container">
                        <h3>Store Integration</h3>
                        {/* <h6>Last Modified: July 22 24</h6> */}
                    </div>
                    <div className="privacy-policy-div">
                        <h6 style={{ fontSize: "18px", fontWeight: "700" }}>Store Integration for Deserts Dropshipper API</h6>
                        <h6 style={{ fontSize: "18px", fontWeight: "700" }}>1. Product APIs</h6>

                        <p style={{ fontSize: "14px", fontWeight: "500", marginTop: "10px" }}>
                            <strong style={{ paddingTop: "0.5%" }}>Get All Products: </strong>
                            https://backend.desertsdropshipper.com/api/v1/products
                            <FontAwesomeIcon icon={faClipboard} color="black" style={{ marginLeft: "20px", cursor: "pointer" }}
                                onClick={() => copyToClipboard('https://backend.desertsdropshipper.com/api/v1/products')} />
                        </p>

                        <p style={{ fontSize: "14px", fontWeight: "500", marginTop: "10px" }}>
                            <strong style={{ paddingTop: "0.5%" }}>Get Product By Category: </strong>
                            https://backend.desertsdropshipper.com/api/v1/products/category/:category
                            <FontAwesomeIcon icon={faClipboard} color="black" style={{ marginLeft: "20px", cursor: "pointer" }}
                                onClick={() => copyToClipboard('https://backend.desertsdropshipper.com/api/v1/products/category/:category')} />
                        </p>

                        <p style={{ fontSize: "14px", fontWeight: "500", marginTop: "10px" }}>
                            <strong style={{ paddingTop: "0.5%" }}>Get Product By ID: </strong>
                            https://backend.desertsdropshipper.com/api/v1/products/:id
                            <FontAwesomeIcon icon={faClipboard} color="black" style={{ marginLeft: "20px", cursor: "pointer" }}
                                onClick={() => copyToClipboard('https://backend.desertsdropshipper.com/api/v1/products/:id')} />
                        </p>

                        <h6 style={{ fontSize: "18px", fontWeight: "700" }}>2. Category APIs</h6>

                        <p style={{ fontSize: "14px", fontWeight: "500", marginTop: "10px" }}>
                            <strong style={{ paddingTop: "0.5%" }}>Get All Categories: </strong>
                            https://backend.desertsdropshipper.com/api/v1/categories
                            <FontAwesomeIcon icon={faClipboard} color="black" style={{ marginLeft: "20px", cursor: "pointer" }}
                                onClick={() => copyToClipboard('https://backend.desertsdropshipper.com/api/v1/categories')} />
                        </p>

                        <p style={{ fontSize: "14px", fontWeight: "500", marginTop: "10px" }}>
                            <strong style={{ paddingTop: "0.5%" }}>Get Category By ID: </strong>
                            https://backend.desertsdropshipper.com/api/v1/categories/:id
                            <FontAwesomeIcon icon={faClipboard} color="black" style={{ marginLeft: "20px", cursor: "pointer" }}
                                onClick={() => copyToClipboard('https://backend.desertsdropshipper.com/api/v1/categories/:id')} />
                        </p>
                    </div>
                </div>
            </div>
            <Footer instagramData={instagramData} />
        </>
    );
}

export default withAppContext(StoreIntegration);
