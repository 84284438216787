import React, { Suspense, useEffect } from 'react';
import { Route, BrowserRouter as Router, Routes, useLocation, } from 'react-router-dom';
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import LoaderView from './components/Loader';
import ScrollToTop from './components/ScrollToTop';
import Whatsapp from './components/Whatsapp';
import AppProvider from './context';
import About from './pages/About';
import AllProduct from './pages/AllProduct';
import Login from './pages/authentication/login';
import Register from './pages/authentication/register';
import Cart from './pages/Cart';
import CheckoutDetail from './pages/Checkout';
import Contact from './pages/Contact';
import NewPage from './pages/index';
import OrderHistory from './pages/OrderHistory';
import Otp from './pages/Otp';
import Privacy from './pages/PrivacyPolicy';
import ProductDetail from './pages/ProductDetail';
import Reward from './pages/Reward';
import RewardCelebration from './pages/RewardCelebration';
import StoreIntegration from './pages/StoreIntegration';
import Transaction from './pages/Transaction';
import Wishlist from './pages/Wishlist';
import ReactPixel from 'react-facebook-pixel';

function usePageTracking() {
  useEffect(() => {
    ReactPixel.pageView(); // Trigger a page view for each route change
  }, []);
}

function App() {
  useEffect(() => {
    ReactPixel.init('578576101519426'); // Replace with your Pixel ID
    ReactPixel.pageView(); // For the initial page load
  }, []);

  usePageTracking();

  return (
    <AppProvider>
      <ToastContainer
        style={{
          zIndex: 9999999999999999999999999n,
        }}
      />
      <Suspense fallback={<LoaderView />}>
        <Router>
          <Routes>
            <Route path="/Login" element={<Login />} />
            <Route path="/Register" element={<Register />} />
            <Route path="/" element={<NewPage />} />
            <Route path="/PrivacyPolicy" element={<Privacy />} />
            <Route path="/Contact" element={<Contact />} />
            <Route path="/About" element={<About />} />
            <Route path="/Cart" element={<Cart />} />
            <Route path="/Checkout" element={<CheckoutDetail />} />
            <Route path="/Otp" element={<Otp />} />
            <Route path="/OrderHistory" element={<OrderHistory />} />
            <Route path="/Transaction" element={<Transaction />} />
            <Route path="/WishList" element={<Wishlist />} />
            <Route path="/RewardCelebration" element={<RewardCelebration />} />
            <Route path="/Reward" element={<Reward />} />
            <Route path="/AllProduct/:category" element={<AllProduct />} />
            <Route path="/ProductDetail" element={<ProductDetail />} />
            <Route path="/StoreIntegration" element={<StoreIntegration />} />
          </Routes>
        </Router>
      </Suspense>
      <ScrollToTop />
      <Whatsapp />
    </AppProvider>
  );
}

export default App;
