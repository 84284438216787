import "./index.css"
import Logo from "../../images/desertsdroppshiper.png"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocation, faPhone, faEnvelope, faClock } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faInstagram, faYoutube, faTwitter, faTiktok, } from '@fortawesome/free-brands-svg-icons';
import P1 from "../../images/p1.png"
import P2 from "../../images/p2.png"
import P3 from "../../images/p3.png"
import P4 from "../../images/p4.png"
import P5 from "../../images/p5.png"
import P6 from "../../images/p6.png"
import { useNavigate } from "react-router-dom";
import { BACKEND_Image_URL } from "../../env";


function Footer({ instagramData }) {
    const navigate = useNavigate()
    const handleClickFacebook = () => {
        const url = `https://www.facebook.com/Desertdropshiping.co`;
        window.open(url, "_blank"); // Opens the URL in a new tab or window
    };
    const handleClickTikTok = () => {
        const url = `https://www.tiktok.com/@desertsdropshipper/photo/7377291964774960430?is_from_webapp=1&sender_device=pc&web_id=7401764930369078800`;
        window.open(url, "_blank"); // Opens the URL in a new tab or window
    };
    const handleClickYoutube = () => {
        const url = `https://www.youtube.com/@DesertsDropshipper`;
        window.open(url, "_blank"); // Opens the URL in a new tab or window
    };
    const handleClickInstagram = () => {
        const url = `https://www.instagram.com/desertsdropshipper/`;
        window.open(url, "_blank"); // Opens the URL in a new tab or window
    };
    return (
        <section style={{ background: "#B43F3F" }}>
            <div className="footer-main">
                <div className="footer-main-div">
                    <img src={Logo} alt="" />
                    <ul className="footer-internal-link">
                        <li className="footer-internal-link-li" onClick={() => navigate("/")}>Home</li>
                        <li className="footer-internal-link-li" onClick={() => navigate("/About")}>About us</li>
                        <li className="footer-internal-link-li" onClick={() => navigate("/PrivacyPolicy")}>Privacy Policy</li>
                        <li className="footer-internal-link-li" onClick={() => navigate("/Contact")}>Contact us</li>
                    </ul>
                    <div className="social-link">
                        <div className="social-link-div" onClick={() => handleClickFacebook()}> <FontAwesomeIcon icon={faFacebook} color="white" /></div>
                        <div className="social-link-div" onClick={() => handleClickInstagram()}><FontAwesomeIcon icon={faInstagram} color="white" /></div>
                        <div className="social-link-div" onClick={() => handleClickYoutube()}><FontAwesomeIcon icon={faYoutube} color="white" /></div>
                        <div className="social-link-div" onClick={() => handleClickTikTok()}><FontAwesomeIcon icon={faTiktok} color="white" /></div>
                    </div>
                </div>
            </div>
            <div className="other-information-container">
                <div className="footer-contact-info-container">
                    <h6>CONTACT INFO</h6>
                    <div className="footer-underline"></div>
                    <ul className="footer-contact-info-list">
                        <li>
                            <FontAwesomeIcon icon={faLocation} color="" style={{ marginRight: "8px" }} />
                            Warehouse 16 street 6C Street - behind kangaroo plastics - Al Khabaisi - Dubai
                        </li>

                        <li>
                            <FontAwesomeIcon icon={faPhone} color="" style={{ marginRight: "8px" }} />
                            +971562779487
                        </li>
                        <li>
                            <FontAwesomeIcon icon={faEnvelope} color="" style={{ marginRight: "8px" }} />
                            desertsdropshipperinfo@gmail.com
                        </li>
                        {/* <li>
                            <FontAwesomeIcon icon={faClock} color="" style={{ marginRight: "8px" }} />
                            Open Time: 8:00AM - 6:00PM
                        </li> */}

                    </ul>
                </div>
                {/* <div className="footer-account-info-container">
                    <h6>MY ACCOUNT</h6>
                    <div className="footer-underline"></div>
                    <ul className="footer-account-info-list">
                        <li>
                            Brand
                        </li>
                        <li>
                            Gift Certificattes                        </li>
                        <li>
                            Affilates
                        </li>
                        <li>
                            Special
                        </li>
                        <li>
                            FAQs
                        </li>
                        <li>
                            Customn Link
                        </li>
                    </ul>
                </div> */}
                <div className="footer-account-info-container">
                    <h6>INFORMATION</h6>
                    <div className="footer-underline"></div>
                    <ul className="footer-account-info-list">
                        <li>
                            About us
                        </li>
                        <li>
                            FAQ
                        </li>
                        <li>
                            Warranty And Srvices
                        </li>
                        <li>
                            Support 24/7 page
                        </li>
                        <li>
                            Product Registration
                        </li>
                        <li>
                            Product Support
                        </li>
                    </ul>
                </div>
                <div className="footer-instagram-container">
                    <h6>INSTAGRAM GALLERY</h6>
                    <div className="footer-underline"></div>
                    <div className="footer-insta-container">
                        {instagramData?.map((e, i) => (
                            <img src={`${BACKEND_Image_URL}${e.Image}`} alt="" className="footer-insta-image" />
                        ))}

                        {/* <img src={P2} alt="" className="footer-insta-image" />
                        <img src={P3} alt="" className="footer-insta-image" />
                        <img src={P4} alt="" className="footer-insta-image" />
                        <img src={P5} alt="" className="footer-insta-image" />
                        <img src={P6} alt="" className="footer-insta-image" /> */}
                    </div>
                </div>
            </div>
        </section>
    )
}
export default Footer