// import Paper from '@mui/material/Paper';
// import Table from '@mui/material/Table';
// import TableBody from '@mui/material/TableBody';
// import TableCell from '@mui/material/TableCell';
// import TableContainer from '@mui/material/TableContainer';
// import TableHead from '@mui/material/TableHead';
// import TableRow from '@mui/material/TableRow';
// import axios from 'axios';
// import * as React from 'react';
// import { useEffect } from 'react';
// import Footer from "../../components/Footer";
// import Navbar from "../../components/Navbar";
// import Sidebar from '../../components/sidebar';
// import { withAppContext } from '../../context';
// import { BACKEND_Image_URL, BACKEND_URL } from '../../env';
// import "./index.css";
// import { useState } from 'react';

// function OrderHistory({ AllCategory, WishlistData, instagramData }) {
//     const [sidebarOpen, setSidebarOpen] = useState(false);
//     const [orders, setOrders] = useState([]);
//     const storedLoginData = JSON.parse(localStorage.getItem("loginData"));

//     useEffect(() => {
//         // Fetch orders for the logged-in user
//         const fetchOrders = async () => {
//             try {
//                 const response = await axios.get(`${BACKEND_URL}/orders/user/${storedLoginData.id}`, {
//                     headers: {
//                         token: storedLoginData.token,
//                     }
//                 });
//                 setOrders(response.data);
//             } catch (error) {
//                 console.error('Failed to fetch orders', error);
//             }
//         };

//         fetchOrders();
//     }, []);

//     const openSidebar = () => {
//         setSidebarOpen(true);
//     }

//     const closeSidebar = () => {
//         setSidebarOpen(false);
//     }
//     const formatDate = (dateString) => {
//         const date = new Date(dateString);
//         return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
//     };

//     return (
//         <div>
//             <Navbar openSidebar={openSidebar} AllCategory={AllCategory} WishlistData={WishlistData} />
//             <Sidebar closeSidebar={closeSidebar} sidebarOpen={sidebarOpen} />
//             <div style={{ width: "85%", margin: "30px auto" }}>
//                 <h1 style={{ fontFamily: "Kanit", marginBottom: "20px", fontSize: "20px" }}>Order History</h1>
//                 <TableContainer component={Paper} className='table-scroll'>
//                     <Table sx={{ minWidth: 900 }} aria-label="simple table">
//                         <TableHead>
//                             <TableRow>
//                                 <TableCell style={{ fontFamily: "Kanit", fontSize: "14px", fontWeight: "700" }}>Order ID</TableCell>
//                                 <TableCell style={{ fontFamily: "Kanit", fontSize: "14px", fontWeight: "700" }} align="left">Product</TableCell>
//                                 <TableCell style={{ fontFamily: "Kanit", fontSize: "14px", fontWeight: "700" }} align="left">Date</TableCell>
//                                 <TableCell style={{ fontFamily: "Kanit", fontSize: "14px", fontWeight: "700" }} align="left">Total Amount</TableCell>
//                                 <TableCell style={{ fontFamily: "Kanit", fontSize: "14px", fontWeight: "700" }} align="left">Status</TableCell>
//                             </TableRow>
//                         </TableHead>
//                         <TableBody>
//                             {orders.map((order) => (
//                                 order.products.length > 0 ? (
//                                     order.products.map((product) => (
//                                         <TableRow
//                                             key={product.id}
//                                             sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
//                                         >
//                                             <TableCell component="th" scope="row">
//                                                 {order.orderId}
//                                             </TableCell>
//                                             <TableCell align="left">
//                                                 <div style={{ display: "flex", alignItems: "center" }}>
//                                                     <img src={`${BACKEND_Image_URL}${product?.images[0]}`} alt='' width={40} height={40} />
//                                                     <div style={{ marginLeft: "10px" }}>
//                                                         <h6 style={{ fontFamily: "Kanit", margin: 0, fontSize: "14px", fontWeight: "600" }}>{product.title}</h6>
//                                                     </div>
//                                                 </div>
//                                             </TableCell>
//                                             <TableCell style={{ fontFamily: "Kanit", margin: 0, fontSize: "14px", fontWeight: "500", color: "#878a99" }} align="left">{formatDate(order.orderDate)}</TableCell>
//                                             <TableCell style={{ fontFamily: "Kanit", margin: 0, fontSize: "14px", fontWeight: "500", color: "#222" }} align="left">{product.price * product.OrderProduct.quantity} Aed</TableCell>
//                                             <TableCell align="left">
//                                                 <div style={{ background: "#cdf7ec", textAlign: "center", color: "rgb(6, 214, 160)", fontSize: "12px", fontWeight: "500", fontFamily: "Kanit", borderRadius: "12px", padding: "4px" }}>{order.status}</div>
//                                             </TableCell>
//                                         </TableRow>
//                                     ))
//                                 ) : (
//                                     <TableRow
//                                         key={order.id}
//                                         sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
//                                     >
//                                         <TableCell component="th" scope="row">
//                                             {order.orderId}
//                                         </TableCell>
//                                         <TableCell align="left">No Products</TableCell>
//                                         <TableCell style={{ fontFamily: "Kanit", margin: 0, fontSize: "14px", fontWeight: "500", color: "#878a99" }} align="left">{formatDate(order.orderDate)}</TableCell>
//                                         <TableCell style={{ fontFamily: "Kanit", margin: 0, fontSize: "14px", fontWeight: "500", color: "#222" }} align="left">{order.totalAmount} Aed</TableCell>
//                                         <TableCell align="left">
//                                             <div style={{ background: "#cdf7ec", textAlign: "center", color: "rgb(6, 214, 160)", fontSize: "12px", fontWeight: "500", fontFamily: "Kanit", borderRadius: "12px", padding: "4px" }}>{order.status}</div>
//                                         </TableCell>
//                                     </TableRow>
//                                 )
//                             ))}
//                         </TableBody>
//                     </Table>
//                 </TableContainer>
//             </div>
//             <Footer instagramData={instagramData} />
//         </div>
//     );
// }

// export default withAppContext(OrderHistory);
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import axios from 'axios';
import * as React from 'react';
import { useEffect, useState } from 'react';
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import Sidebar from '../../components/sidebar';
import { withAppContext } from '../../context';
import { BACKEND_Image_URL, BACKEND_URL } from '../../env';
import "./index.css";
import NotFound from "../../images/notFound.webp";
function OrderHistory({ AllCategory, WishlistData, instagramData }) {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [orders, setOrders] = useState([]);
    const [trackingInfo, setTrackingInfo] = useState({});
    const storedLoginData = JSON.parse(localStorage.getItem("loginData"));

    useEffect(() => {
        // Fetch orders for the logged-in user
        const fetchOrders = async () => {
            try {
                const response = await axios.get(`${BACKEND_URL}/orders/user/${storedLoginData.id}`, {
                    headers: {
                        token: storedLoginData.token,
                    }
                });
                setOrders(response.data);
                // Fetch tracking information from Finix
                fetchTrackingInfo(response.data);
            } catch (error) {
                console.error('Failed to fetch orders', error);
            }
        };

        const fetchTrackingInfo = async (orders) => {
            try {
                const trackingResponses = await Promise.all(
                    orders.map(order =>
                        axios.get(`https://finix-api-url.com/track/${order.orderId}`, {
                            headers: {
                                token: storedLoginData.token, // Add any required authentication
                            }
                        })
                    )
                );
                const trackingData = trackingResponses.reduce((acc, response, index) => {
                    acc[orders[index].orderId] = response.data;
                    return acc;
                }, {});
                setTrackingInfo(trackingData);
            } catch (error) {
                console.error('Failed to fetch tracking information', error);
            }
        };

        fetchOrders();
    }, []);

    const openSidebar = () => {
        setSidebarOpen(true);
    }

    const closeSidebar = () => {
        setSidebarOpen(false);
    }

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
    };

    return (
        <div>
            <Navbar openSidebar={openSidebar} AllCategory={AllCategory} WishlistData={WishlistData} />
            <Sidebar closeSidebar={closeSidebar} sidebarOpen={sidebarOpen} />
            <div style={{ width: "85%", margin: "30px auto" }}>
                <h1 style={{ fontFamily: "Kanit", marginBottom: "20px", fontSize: "20px" }}>Order History</h1>
                <TableContainer component={Paper} className='table-scroll'>
                    <Table sx={{ minWidth: 900 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ fontFamily: "Kanit", fontSize: "14px", fontWeight: "700" }}>Order ID</TableCell>
                                <TableCell style={{ fontFamily: "Kanit", fontSize: "14px", fontWeight: "700" }} align="left">Product</TableCell>
                                <TableCell style={{ fontFamily: "Kanit", fontSize: "14px", fontWeight: "700" }} align="left">Date</TableCell>
                                <TableCell style={{ fontFamily: "Kanit", fontSize: "14px", fontWeight: "700" }} align="left">Total Amount</TableCell>
                                <TableCell style={{ fontFamily: "Kanit", fontSize: "14px", fontWeight: "700" }} align="left">Status</TableCell>
                                {/* <TableCell style={{ fontFamily: "Kanit", fontSize: "14px", fontWeight: "700" }} align="left">Tracking</TableCell> */}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {orders.map((order) => (
                                order.products.length > 0 ? (
                                    order.products.map((product) => (
                                        <TableRow
                                            key={product.id}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row">
                                                {order.orderId}
                                            </TableCell>
                                            <TableCell align="left">
                                                <div style={{ display: "flex", alignItems: "center" }}>
                                                    <img src={ product?.images?.length > 0 ?
                                                        product?.images[0]?.startsWith("products/http") || product?.images[0]?.startsWith("products/https")
                                                            ? product?.images[0]?.replace("products/", "")
                                                            : `${BACKEND_Image_URL}${product?.images[0]}`:NotFound
                                                    } alt='' width={40} height={40} />
                                                    <div style={{ marginLeft: "10px" }}>
                                                        <h6 style={{ fontFamily: "Kanit", margin: 0, fontSize: "14px", fontWeight: "600" }}>{product.title}</h6>
                                                    </div>
                                                </div>
                                            </TableCell>
                                            <TableCell style={{ fontFamily: "Kanit", margin: 0, fontSize: "14px", fontWeight: "500", color: "#878a99" }} align="left">{formatDate(order.orderDate)}</TableCell>
                                            <TableCell style={{ fontFamily: "Kanit", margin: 0, fontSize: "14px", fontWeight: "500", color: "#222" }} align="left">{product.price * product.orderproduct?.quantity} Aed</TableCell>
                                            <TableCell align="left">
                                                <div style={{ background: "#cdf7ec", textAlign: "center", color: "rgb(6, 214, 160)", fontSize: "12px", fontWeight: "500", fontFamily: "Kanit", borderRadius: "12px", padding: "4px" }}>{order.status}</div>
                                            </TableCell>
                                            {/* <TableCell align="left">
                                                {trackingInfo[order.orderId] ? trackingInfo[order.orderId].status : 'Loading...'}
                                            </TableCell> */}
                                        </TableRow>
                                    ))
                                ) : (
                                    <TableRow
                                        key={order.id}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row">
                                            {order.orderId}
                                        </TableCell>
                                        <TableCell align="left">No Products</TableCell>
                                        <TableCell style={{ fontFamily: "Kanit", margin: 0, fontSize: "14px", fontWeight: "500", color: "#878a99" }} align="left">{formatDate(order.orderDate)}</TableCell>
                                        <TableCell style={{ fontFamily: "Kanit", margin: 0, fontSize: "14px", fontWeight: "500", color: "#222" }} align="left">{order.totalAmount} Aed</TableCell>
                                        <TableCell align="left">
                                            <div style={{ background: "#cdf7ec", textAlign: "center", color: "rgb(6, 214, 160)", fontSize: "12px", fontWeight: "500", fontFamily: "Kanit", borderRadius: "12px", padding: "4px" }}>{order.status}</div>
                                        </TableCell>
                                        <TableCell align="left">
                                            {trackingInfo[order.orderId] ? trackingInfo[order.orderId].status : 'Loading...'}
                                        </TableCell>
                                    </TableRow>
                                )
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
            <Footer instagramData={instagramData} />
        </div>
    );
}

export default withAppContext(OrderHistory);
