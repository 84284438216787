import React, { useState } from "react";
import "./Login.css";
import { useNavigate } from "react-router-dom";
import { BACKEND_URL } from "../../env";
import { toast } from "react-toastify";
import { CircularProgress } from "@mui/material";
import Logo from "../../images/desertsdroppshiper.png";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import ReactPixel from 'react-facebook-pixel';
const LoginForm = () => {
  const [password, setPassword] = useState("")
  const [email, setEmail] = useState("")
  const [loading, setLoading] = useState(false)
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate()

  const handleSubmit = async () => {
    if (!email || !password) {
      toast.error('Please Fill Input');
    } else {
      setLoading(true);
      const myHeaders = new Headers();
      myHeaders.append('Content-Type', 'application/json');

      const raw = JSON.stringify({
        email: email,
        password: password,
        isActive: true
      });

      const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow',
      };

      try {
        const response = await fetch(
          `${BACKEND_URL}/auth/signin`,
          requestOptions
        );
        const result = await response.json();

        setLoading(false);

        if (result.status === 400 || result.error) {
          toast.error(result.message);
        } else {
          // await localStorage.setItem('loginData', JSON.stringify(result));
          ReactPixel.track('Login', { email });
          navigate('/Otp', { state: email });
          setEmail('');
          setPassword('');
        }
      } catch (error) {
        console.error('Error:', error);
        toast.error('An error occurred. Please try again.');
        setLoading(false);
      }
    }
  };
  return (
    <div className="login-container">
      <div className="login-wrapper">
        <div className="login-logo">
          <img src={Logo} alt='' width={230} height={80} />
        </div>
        <h2 className="login-heading">Login</h2>
        <div className="Login-input-div">
          <label className="lable">Email*</label>
          <div className="name-input-div">
            <input
              type="text"
              placeholder="Email"
              // className="register-input"
              className="register-input"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
        </div>
        <div className="Login-input-div">
          <label className="lable">Password*</label>
          <div className="name-input-div">
            <input
              placeholder="Password"
              // className="register-input"
              className="register-input"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              type={showPassword ? "text" : "password"}
            />
            <span
              className="password-toggle-icon"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? <VisibilityOff /> : <Visibility />} 
            </span>
          </div>
        </div>
        <div className="login-btn-container">
          <button className="login-btn" onClick={() => handleSubmit()}>{loading ? <CircularProgress size={20} sx={{ color: "#fff" }} /> : "Login"}</button>
        </div>
        <div className="not-account">
          <p>Don't have an account? <a href="/Register">Register</a></p>
        </div>
      </div>
    </div>
  );
};

export default LoginForm;
