import React, { useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';

// The forwardRef is important!!
// Dropdown needs access to the DOM node in order to position the Menu
const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
        href=""
        ref={ref}
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}
        style={{
            fontFamily: "Kanit",
            textDecoration: "none",
            color: "#444",
            fontSize: "13px",
            visibility: "visible !important",
            opacity: 1,
            display: "contents",
        }}
    >
        {children}
        &#x25bc;
    </a>
));

// forwardRef again here!
// Dropdown needs access to the DOM of the Menu to measure it
const CustomMenu = React.forwardRef(
    ({ children, className, 'aria-labelledby': labeledBy }, ref) => {
        const [value, setValue] = useState('');

        return (
            <div
                ref={ref}
                className={className}
                aria-labelledby={labeledBy}
            >
                <ul className="list-unstyled">
                    {React.Children.toArray(children).filter(
                        (child) =>
                            !value || child.props.children.toLowerCase().startsWith(value),
                    )}
                </ul>
            </div>
        );
    },
);

function SidebarDropdowns({ onSelect }) {
    const [selectedState, setSelectedState] = useState('');

    const handleSelect = (eventKey) => {
        const selectedText = categoryStates[eventKey];
        setSelectedState(selectedText);
        if (onSelect) {
            onSelect(selectedText);
        }
    };

    const categoryStates = ["Men", "Woman", "Kid",];

    return (
        <Dropdown onSelect={handleSelect} style={{ inset: "none !important", transform: "none !important" }}>
            <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components" style={{
                fontFamily: "Kanit", fontWeight: "600", fontSize: "0.9rem", visibility: "visible",
                opacity: 1,
                display: "contents",
            }}>
                {selectedState || "Category"}
            </Dropdown.Toggle>
            <Dropdown.Menu as={CustomMenu} style={{ width: "150px !important", marginTop: "10px" }}>
                {categoryStates.map((state, index) => (
                    <Dropdown.Item key={index} eventKey={index} style={{ fontFamily: "Kanit", textDecoration: "none", color: "#444", fontSize: "13px" }}>
                        {state}
                    </Dropdown.Item>
                ))}
            </Dropdown.Menu>
        </Dropdown>
    );
}

export default SidebarDropdowns;
