import React, { useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';

// The forwardRef is important!!
// Dropdown needs access to the DOM node in order to position the Menu
const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
        href=""
        ref={ref}
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}
        style={{ fontFamily: "Kanit", textDecoration: "none", color: "#444", fontSize: "13px", }}
    >
        {children}
        &#x25bc;
    </a>
));

// forwardRef again here!
// Dropdown needs access to the DOM of the Menu to measure it
const CustomMenu = React.forwardRef(
    ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
        const [value, setValue] = useState('');

        return (
            <div
                ref={ref}
                style={style}
                className={className}
                aria-labelledby={labeledBy}
            >
                {/* <Form.Control
                    autoFocus
                    className="mx-3 my-2 "
                    // placeholder="Type to filter..."
                    onChange={(e) => setValue(e.target.value)}
                    value={value}
                    style={{width:"130px", fontFamily: "Kanit", textDecoration: "none", color: "#444", fontSize: "13px" }}
                /> */}
                <ul className="list-unstyled">
                    {React.Children.toArray(children).filter(
                        (child) =>
                            !value || child.props.children.toLowerCase().startsWith(value),
                    )}
                </ul>
            </div>
        );
    },
);
function PaymentDropdown({ onSelect }) {
    const [selectedState, setSelectedState] = useState('');

    const handleSelect = (eventKey) => {
        const selectedText = categoryStates[eventKey];
        setSelectedState(selectedText);
        if (onSelect) {
            onSelect(selectedText);
        }
    };

    const categoryStates = ["JaazCash", "EasyPaisa", "BankAccount"];
    return (
        <Dropdown onSelect={handleSelect} style={{ border: "1px solid #555", padding: "10px", borderRadius: "10px" }}>
            <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                {selectedState || "All Method"}
            </Dropdown.Toggle>

            <Dropdown.Menu as={CustomMenu} style={{ width: "150px !important", marginTop: "10px" }}>
                {categoryStates.map((state, index) => (

                    <Dropdown.Item key={index} eventKey={index} style={{ fontFamily: "Kanit", textDecoration: "none", color: "#444", fontSize: "13px" }}>{state}</Dropdown.Item>
                ))}
            </Dropdown.Menu>
        </Dropdown>
    );
}
export default PaymentDropdown