import { useEffect, useState } from "react";
import Category from "../components/Category";
import FeatureProduct from "../components/FeatureProduct";
import Footer from "../components/Footer";
import LoginPopup from "../components/LoginPopup";
import Navbar from "../components/Navbar";
import Sliders from "../components/Product";
import Sidebar from "../components/sidebar";
import TopTags from "../components/TopTags";
import Trending from "../components/Trending";
import { withAppContext } from "../context";
function NewPage({ AllCategory, GetAllCategories, getTags, TagsData, AllProducts, GetAllProducts, loginData, WishlistData, getWishlist, getSlider, sliderData, getInstagram, instagramData }) {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [loginModal, setLoginModal] = useState(false)
    const [searchQuery, setSearchQuery] = useState("");
    const openSidebar = () => {
        setSidebarOpen(true);
    }

    const closeSidebar = () => {
        setSidebarOpen(false);
    }
    const openLoginModel = () => {
        setLoginModal(!loginModal)

    }
    useEffect(() => {
        GetAllCategories();
        getTags()
        GetAllProducts()
        getWishlist()
        getSlider()
        getInstagram()
    }, []);
    return (
        <>
            {loginModal ? <LoginPopup overlayClick={openLoginModel} /> : null}
            <Navbar openSidebar={openSidebar} AllCategory={AllCategory} WishlistData={WishlistData} searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
            <Sidebar closeSidebar={closeSidebar} sidebarOpen={sidebarOpen} />
            <div className="category-slider">
                <Category AllCategory={AllCategory} />
                <Sliders sliderData={sliderData} />
            </div>
            <div className="tags-trending" >
                <TopTags getTags={TagsData} />
                <Trending onClick={openLoginModel} AllCategory={AllCategory} AllProducts={AllProducts} getWishlist={getWishlist} />
            </div>
            <div>
                <FeatureProduct searchQuery={searchQuery} onClick={openLoginModel} AllProducts={AllProducts} loginData={loginData} getWishlist={getWishlist} />
            </div>
            <Footer instagramData={instagramData} />
            {/* <ScrollToTop/> */}
        </>
    )
}
export default withAppContext(NewPage)