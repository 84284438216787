import React, { useState } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import Logo from "../../images/desertsdroppshiper.png";
import "./index.css";
import { BACKEND_URL } from "../../env";
import { toast } from "react-toastify";
import { CircularProgress } from "@mui/material";

function Otp() {
    const [otp, setOtp] = useState("");
    const [loading, setLoading] = useState("");
    const navigate = useNavigate();
    const location = useLocation()
    const email = location.state; // Replace with the actual email from the previous step

    const handleOtpSubmit = async () => {
        setLoading(true)
        try {
            const response = await axios.post(`${BACKEND_URL}/auth/verify-otp`, {
                email,
                otp,
            });

            if (response.data.token) {
                setLoading(false)
                setOtp("")
                await localStorage.setItem('loginData', JSON.stringify(response.data));
                navigate("/");
                toast.success("OTP verified successfully!")
            }
        } catch (error) {
            setLoading(false)
            console.error("Error verifying OTP:", error);
            toast.error("Invalid or expired OTP. Please try again.");
        }
    };

    return (
        <div className="otp-main">
            <div className="otp-container">
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100%",
                        marginBottom: "20px",
                    }}
                >
                    <img src={Logo} alt="" width={250} />
                </div>
                <h1 className="enter-code-heading">Enter Code</h1>
                <p className="sent-email-text">Sent to {email}</p>
                <input
                    placeholder="6-digit code"
                    className="otp-input"
                    value={otp}
                    onChange={(e) => setOtp(e.target.value)}
                />
                <button className="otp-submit-btn" onClick={handleOtpSubmit}>
                    {/*  */}
                    {loading ? <CircularProgress size={20} sx={{ color: "#fff" }} /> : "Submit"}
                </button>
            </div>
        </div>
    );
}

export default Otp;
