// import React from "react";
// import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
// import { Doughnut } from "react-chartjs-2";

// ChartJS.register(ArcElement, Tooltip, Legend);

// function ChartComponent() {
//   const data = [
//     {
//       label: "Progress",
//       value: 60,
//       color: "rgba(232, 96, 41, 1)",
//     },
//   ];

//   const finalData = {
//     labels: data.map((item) => item.label),
//     datasets: [
//       {
//         data: [data[0].value, 100 - data[0].value],
//         backgroundColor: [data[0].color, "rgba(200, 200, 200, 0.2)"], // Background color for progress and remaining
//         borderColor: [data[0].color, "rgba(200, 200, 200, 0.2)"],
//         borderWidth: 1,
//       },
//     ],
//   };

//   const options = {
//     responsive: true,
//     plugins: {
//       legend: {
//         display: false,
//       },
//     },
//     cutout: "60%", // Adjust the cutout percentage as needed
//   };

//   return <Doughnut data={finalData} options={options} />;
// }

// export default ChartComponent;
// ChartComponent.js
import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

function ChartComponent({ completed }) {
  // Calculate progress as a percentage
  const progress = completed ;

  const data = [
    {
      label: "Progress",
      value: progress,
      color: "rgba(232, 96, 41, 1)",
    },
  ];

  const finalData = {
    labels: data.map((item) => item.label),
    datasets: [
      {
        data: [data[0].value, 100 - data[0].value],
        backgroundColor: [data[0].color, "rgba(200, 200, 200, 0.2)"], // Background color for progress and remaining
        borderColor: [data[0].color, "rgba(200, 200, 200, 0.2)"],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
    cutout: "75%", // Adjust the cutout percentage as needed
  };

  return <Doughnut data={finalData} options={options} />;
}

export default ChartComponent;
