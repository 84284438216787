import { faBalanceScale, faMoneyBill } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from "react";
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import Sidebar from "../../components/sidebar";
import "./index.css";
import OrderComplete from "../../images/package.png";
import OrderCancelled from "../../images/cancel-order.png";
import OrderProccess from "../../images/process.png";
import ChartComponent from '../../components/Chart';
import { useNavigate } from 'react-router-dom';
import PaymentDropdown from '../../components/PaymentDropdown';
import { withAppContext } from '../../context';
import axios from 'axios';
import { BACKEND_URL } from '../../env';
import { toast } from 'react-toastify';
import { CircularProgress } from '@mui/material';

function Reward({ AllCategory, WishlistData, instagramData }) {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [orders, setOrders] = useState([]);
    const [user, setUser] = useState({});
    const [method, setMethod] = useState("")
    const [accountNumber, setAccountNumber] = useState("")
    const [accountName, setAccountName] = useState("")
    const [amount, setAmount] = useState("")
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate();

    const openSidebar = () => {
        setSidebarOpen(true);
    };

    const closeSidebar = () => {
        setSidebarOpen(false);
    };

    const storedLoginData = JSON.parse(localStorage.getItem("loginData"));

    useEffect(() => {
        const fetchOrders = async () => {
            try {
                const response = await axios.get(`${BACKEND_URL}/orders/user/${storedLoginData.id}`, {
                    headers: {
                        token: storedLoginData.token,
                    }
                });
                setOrders(response.data);
            } catch (error) {
                console.error('Failed to fetch orders', error);
            }
        };

        const fetchUser = async () => {
            try {
                const response = await axios.get(`${BACKEND_URL}/users/${storedLoginData.id}`, {
                    headers: {
                        token: storedLoginData.token,
                    }
                });
                setUser(response.data.user);
            } catch (error) {
                console.error('Failed to fetch user data', error);
            }
        };

        fetchUser();
        fetchOrders();
    }, []);
    const getFilteredOrders = () => {
        const now = new Date();
        const currentYear = now.getFullYear();
        const currentMonth = now.getMonth(); // 0-based (0 = January, 11 = December)

        return orders.filter(order => {
            const orderDate = new Date(order.orderDate);
            return orderDate.getFullYear() === currentYear && orderDate.getMonth() === currentMonth;
        });
    };
    // Calculate the counts of different order statuses
    const getOrderCounts = () => {
        let completed = 0;
        let cancelled = 0;
        let inProcess = 0;
        const filteredOrders = getFilteredOrders();
        filteredOrders?.forEach(order => {
            if (order.status === 'delivered') {
                completed++;
            } else if (order.status === 'cancelled') {
                cancelled++;
            } else if (order.status === 'pending' || order.status === 'shipped') {
                inProcess++;
            }
        });

        return { completed, cancelled, inProcess };
    };

    const { completed, cancelled, inProcess } = getOrderCounts();

    // Calculate the balance based on completed orders
    const calculateBalance = (completedOrders) => {
        if (completedOrders >= 400 || completedOrders === 400)
            return Math.floor(completedOrders * 0.5);
    };

    const balance = calculateBalance(completed);
    console.log(user);

    const handleSubmit = () => {
        setLoading(true)
        // const formData = new FormData();
        let obj = {
            accountName: accountName,
            accountNumber: accountNumber,
            amount: amount,
            method: method,
            storeName: user.storeName,
            email: user.email,
            whatsapp: user.whatsapp,
        }
        // formData.append('accountName', accountName);
        // formData.append('accountNumber', accountNumber);
        // formData.append('amount', amount);
        // formData.append('method', method);
        // formData.append('storeName', user.storeName);
        // formData.append('email', user.email);
        // formData.append('whatsapp', user.whatsapp);
        const config = {
            method: "post",
            url: `${BACKEND_URL}/reward`,
            data: obj,
            headers: {
                token: storedLoginData.token
            }
        };
        axios(config)
            .then((res) => {
                setLoading(false);
                toast.success(`Your Request Submit`, {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                });
                setModalOpen(!modalOpen)
                setAccountName("")
                setAccountNumber("")
                setAmount("")
                setMethod("")
            })
            .catch((res) => {
                setLoading(false);
                toast.error(`Invalid error`, {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                });
            });
    }
    const calculateProgress = (completedOrders) => {
        if (completedOrders >= 400) {
            return 100; // If completed orders reach 400 or more, cap the progress at 100%
        }
        return Math.floor((completedOrders / 400) * 100); // Calculate percentage of completed orders
    };

    const progress = calculateProgress(completed);
    return (
        <div>
            <Navbar openSidebar={openSidebar} AllCategory={AllCategory} WishlistData={WishlistData} />
            <Sidebar closeSidebar={closeSidebar} sidebarOpen={sidebarOpen} />
            {modalOpen ? (
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", margin: "20px 0" }}>
                    <div className='withdarwal-modal'>
                        <PaymentDropdown onSelect={setMethod} />
                        <input className='reward-account-name' placeholder='Enter Account Name' value={accountName} onChange={(e) => setAccountName(e.target.value)} />
                        <input className='reward-account-name' placeholder='Enter Account Number' value={accountNumber} onChange={(e) => setAccountNumber(e.target.value)} />
                        <input className='reward-account-name' placeholder='Enter Amount' value={amount} onChange={(e) => setAmount(e.target.value)} />
                        <button className='reward-submit-btn' onClick={() => handleSubmit()}>{loading ? <CircularProgress size={20} style={{ color: "#fff" }} /> : "Submit"}</button>
                    </div>
                </div>
            ) : (
                <div className="reward-container">
                    <h1 className="reward-main-heading">Reward Detail</h1>
                    <div className="reward-blance-info-container">
                        <ul>
                            {/* <li><strong>Name:</strong> {user.name || 'Loading...'}</li> */}
                            <li><strong>Email:</strong> {user.email || 'Loading...'}</li>
                            <li><strong>Whatsapp:</strong> {user.whatsapp || 'Loading...'}</li>
                        </ul>
                        <div className='balance-withdraw-container'>
                            <div className="reward-balance-container">
                                <p>Balance {balance || 0} AED</p>
                                <FontAwesomeIcon icon={faBalanceScale} color="White" />
                            </div>
                            <div className="reward-balance-container" onClick={() => setModalOpen(!modalOpen)}>
                                <p>Withdrawal</p>
                                <FontAwesomeIcon icon={faMoneyBill} color="White" />
                            </div>
                        </div>
                    </div>
                    <div className="reward-orders-container">
                        <div className='reward-order'>
                            <div className='reward-order-image-text'>
                                <div className='reward-order-image-container'>
                                    <img src={OrderComplete} alt='' width={30} />
                                </div>
                                <div>
                                    <p>Order Completed</p>
                                    <p className='reward-number'>{completed}</p>
                                </div>
                            </div>
                        </div>
                        <div className='reward-order'>
                            <div className='reward-order-image-text'>
                                <div className='reward-order-image-container'>
                                    <img src={OrderCancelled} alt='' width={30} />
                                </div>
                                <div>
                                    <p>Order Cancelled</p>
                                    <p className='reward-number'>{cancelled}</p>
                                </div>
                            </div>
                        </div>
                        <div className='reward-order'>
                            <div className='reward-order-image-text'>
                                <div className='reward-order-image-container'>
                                    <img src={OrderProccess} alt='' width={30} />
                                </div>
                                <div>
                                    <p>Order In Process</p>
                                    <p className='reward-number'>{inProcess}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <marquee className="reward-headline" direction="right">Your {progress}% Reward is Complete. Hurry up, Reward Completed After 400 Order Complete Per Order 0.5 AED Reward</marquee>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%" }}>
                        <div className='reward-chart-container'>
                            <p>{progress}% Progress</p>
                            <ChartComponent completed={progress} />
                        </div>
                    </div>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <button className='reward-claim-btn' onClick={() => navigate("/RewardCelebration")} disabled={completed < 10}>Claim</button>
                    </div>
                </div>
            )}
            <Footer instagramData={instagramData} />
        </div>
    );
}

export default withAppContext(Reward);
