import React from 'react';
import "./index.css";
import Logo from "../../images/desertsdroppshiper.png"
import { useNavigate } from 'react-router-dom';
function Sidebar({ sidebarOpen, closeSidebar }) {
    const navigate = useNavigate()

    return (
        <>
            <div className={`overlay ${sidebarOpen ? 'active' : ''}`} onClick={closeSidebar}></div>
            <div className={`sidebar ${sidebarOpen ? 'open' : ''}`}>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginBottom: "15px" }}>
                    <img src={Logo} alt='' width={200} />
                </div>
                {/* <a href="javascript:void(0)" className="closebtn" onClick={closeSidebar}>&times;</a> */}
                <a className='sidebar-text' href="#" onClick={() => navigate("/")}>Home</a>
                <a className='sidebar-text' href="#" onClick={() => navigate("/About")}>About us</a>
                <a className='sidebar-text' href="#" onClick={() => navigate("/Contact")}>Contact us</a>
                <a className='sidebar-text' href="#" onClick={() => navigate("/PrivacyPolicy")}>Privacy Policy</a>
                <div>
                    <h3 className='sidebar-history-heading'>History</h3>
                    <a className='sidebar-text' href="#" onClick={() => navigate("/orderHistory")}>Order History</a>
                    <a className='sidebar-text' href="#" onClick={() => navigate("/Transaction")}>Transaction History</a>
                </div>
                <div>
                    <h3 className='sidebar-history-heading'>Rewards</h3>
                    <a className='sidebar-text' href="#" onClick={() => navigate("/Reward")}>Rewards</a>
                </div>
            </div>
        </>
    );
}

export default Sidebar;
