import { useNavigate } from "react-router-dom"
import "./index.css"
import { useState } from "react"
import { BACKEND_URL } from "../../env"
import { CircularProgress } from "@mui/material"
import { toast } from "react-toastify"
import { Visibility, VisibilityOff } from "@mui/icons-material";
import ReactPixel from 'react-facebook-pixel';
function Register() {
  const [storeName, setStoreName] = useState("")
  const [email, setEmail] = useState("")
  const [whatsapp, setWhatsapp] = useState("")
  const [cnic, setCnic] = useState("")
  const [bankNumber, setBankNumber] = useState("")
  const [accountTitle, setAccountTitle] = useState("")
  const [accountNumber, setAccountNumber] = useState("")
  const [iban, setIban] = useState("")
  const [password, setPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const [loading, setLoading] = useState(false)
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate()
  const handleSubmit = async () => {
    if (!email) {
      toast.error('Please Email Input');
      return
    }
    if (!password) {
      toast.error('Please Password Input');
      return
    }
    if (!storeName) {
      toast.error('Please Store Name Input');
      return
    }
    if (!storeName) {
      toast.error('Please Password Input');
      return
    }
    if (!whatsapp) {
      toast.error('Please Whatsapp Input');
      return
    }
    if (!cnic) {
      toast.error('Please Cnic Input');
      return
    }
    if (!bankNumber) {
      toast.error('Please Bank Number Input');
      return
    }
    if (!accountTitle) {
      toast.error('Please Account Tite Input');
      return
    }
    if (!accountNumber || !iban || !confirmPassword) {
      toast.error('Please Account Number Input');
      return
    }
    if (!iban || !confirmPassword) {
      toast.error('Please Account iban Input');
      return
    }
    if (!confirmPassword) {
      toast.error('Please Account Confirm Password Input');
      return
    }
    if (whatsapp.length !== 11) {
      toast.error('WhatsApp number must be exactly 11 digits.');
      return;
    }
    if (iban.length !== 24) {
      toast.error('WhatsApp number must be exactly 11 digits.');
      return;
    }
    if (password !== confirmPassword) {
      toast.error('Passwords do not match.');
      return;
    }
    setLoading(true);
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');

    const raw = JSON.stringify({
      email: email,
      password: password,
      whatsapp: whatsapp,
      storeName: storeName,
      cnic: cnic,
      bankNumber: bankNumber,
      accountTitle: accountTitle,
      accountNumber: accountNumber,
      iban: iban,
      isActive: true
    });

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow',
    };

    try {
      const response = await fetch(
        `${BACKEND_URL}/auth/signup`,
        requestOptions
      );
      const result = await response.json();

      setLoading(false);

      if (result.status === 400) {
        toast.error(result.message);
      } else {
        ReactPixel.track('register', { email, storeName, whatsapp });
        await localStorage.setItem('loginData', JSON.stringify(result));
        setEmail('');
        setPassword('');
        setAccountNumber('');
        setAccountTitle('');
        setBankNumber('');
        setCnic('');
        setConfirmPassword('');
        setIban('');
        setStoreName('');
        setWhatsapp('');
        navigate('/login');
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error('An error occurred. Please try again.');
      setLoading(false);
    }

  };
  return (
    <div className="register-main-container">
      <h1 className="reward-main-heading">Seller Registration Form</h1>
      <div className="register-form-container">
        <div className="form-data">
          <div className="register-input-div">
            <label className="lable">Store Name*</label>
            <div className="name-input-div">
              <input
                type="text"
                placeholder="Store Name"
                // className="register-input"
                className="register-input"
                value={storeName}
                onChange={(e) => setStoreName(e.target.value)}
              // required
              />
            </div>
          </div>
          <div className="register-input-div">
            <label className="lable">E-mail*</label>
            <div className="name-input-div">
              <input
                type="text"
                placeholder="E-mail"
                // className="register-input"
                className="register-input"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="form-data">
          <div className="register-input-div">
            <label className="lable">Whatsapp*</label>
            <div className="name-input-div">
              <input
                type="type"
                placeholder="Whatsapp Number - 03160117561 nospace"
                minLength={11}
                maxLength={11}
                className="register-input"
                value={whatsapp}
                onChange={(e) => {
                  if (e.target.value.length <= 11) {
                    setWhatsapp(e.target.value.replace(/\D/g, '')); // Only allow numbers
                  }
                }}
              // onChange={(e) => setWhatsapp(e.target.value)}
              />
            </div>
          </div>
          <div className="register-input-div">
            <label className="lable">CNIC*</label>
            <div className="name-input-div">
              <input
                type="number"
                placeholder="CNIC"
                // className="register-input"
                className="register-input"
                value={cnic}
                onChange={(e) => setCnic(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="form-data">
          <div className="register-input-div">
            <label className="lable">Bank Number*</label>
            <div className="name-input-div">
              <input
                type="number"
                placeholder="Bank Number"
                // className="register-input"
                className="register-input"
                value={bankNumber}
                onChange={(e) => setBankNumber(e.target.value)}
              />
            </div>
          </div>
          <div className="register-input-div">
            <label className="lable">Account Title*</label>
            <div className="name-input-div">
              <input
                type="text"
                placeholder="Account Title"
                // className="register-input"
                className="register-input"
                value={accountTitle}
                onChange={(e) => setAccountTitle(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="form-data">
          <div className="register-input-div">
            <label className="lable">Account Number*</label>
            <div className="name-input-div">
              <input
                type="number"
                placeholder="Account Number"
                // className="register-input"
                className="register-input"
                value={accountNumber}
                onChange={(e) => setAccountNumber(e.target.value)}
              />
            </div>
          </div>
          <div className="register-input-div">
            <label className="lable">IBAN*</label>
            <div className="name-input-div">
              <input
                type="number"
                placeholder="IBAN 24 Digits Without Space"
                className="register-input"
                value={iban}
                onChange={(e) => {
                  if (e.target.value.length <= 24) {
                    setIban(e.target.value.replace(/\D/g, '')); // Only allow numbers
                  }
                }}
                // onChange={(e) => setIban(e.target.value)}
                minLength={24}
                maxLength={24}
              />
            </div>
          </div>
        </div>
        <div className="form-data">
          <div className="register-input-div">
            <label className="lable">Create Password*</label>
            {/* <div className="name-input-div"> */}
            <input
              type={showPassword ? "text" : "password"}
              placeholder="Create Password"
              // className="register-input"
              className="register-input"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <span
              className="password-register-toggle-icon"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </span>
            {/* </div> */}
          </div>
          <div className="register-input-div">
            <label className="lable">Re-Enter Password*</label>
            {/* <div className="name-input-div"> */}
            <input
              type={showPassword ? "text" : "password"}
              placeholder="Re-Enter Password"
              // className="register-input"
              className="register-input"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            <span
              className="password-register-toggle-icon"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </span>
            {/* </div> */}
          </div>
        </div>
        <div className="register-btn-container">
          <button className="register-btn" onClick={() => handleSubmit()}>{loading ? <CircularProgress size={20} sx={{ color: "#fff" }} /> : "Register"}</button>
        </div>
      </div>
    </div>
  )
}
export default Register