import { useEffect, useState } from "react";
import "./index.css";
function ScrollToTop() {
    const [showScrollButton, setShowScrollButton] = useState(false);

    const handleScroll = () => {
        if (window.scrollY > 300) {
            setShowScrollButton(true);
        } else {
            setShowScrollButton(false);
        }
    };

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <>
            {showScrollButton && (
                <button onClick={scrollToTop} className="scroll-to-top">
                     <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" width="24px" height="24px">
                        <path d="M0 0h24v24H0z" fill="none"/>
                        <path d="M7 14l5-5 5 5H7z"/>
                    </svg>
                    {/* <FontAwesomeIcon icon={faArrowUp} color="white" /> */}
                </button>
            )}
        </>
    );
}

export default ScrollToTop;
